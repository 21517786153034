import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; // Import the main App component
import reportWebVitals from './reportWebVitals';
import Nav from './components/Nav.tsx';
import Footer from './components/Footer.tsx';

ReactDOM.render(
  <React.StrictMode>
    <App />
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
