import {
    MapPinIcon,
  } from '@heroicons/react/24/outline'

const people = [
    {
      name: 'Address',
      role: 'City',
      imageUrl:
        'https://images.pexels.com/photos/164558/pexels-photo-164558.jpeg',
      mapUrl: 'https://www.google.com/maps/place/17+Woodcrest+Dr,+East+Moriches,+NY+11940/data=!4m2!3m1!1s0x89e858fa8615518f:0x4855fbc181f7cd6e?sa=X&ved=2ahUKEwiPm6PMpvGCAxVdPEQIHcL6DPUQ8gF6BAgpEAA',
      icon: MapPinIcon,
    },
    // More people...
  ]
  
  export default function Example() {
    return (
      
      <div className="bg-gray-900 py-24 sm:py-32"> 
        <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
          <div className="mx-auto max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Properties</h2>
            <p className="mt-4 text-lg leading-8 text-gray-400">
              We’re a dynamic group of individuals who are passionate about what we do.
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-6 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8"
          >
            {people.map((person) => (
              <li key={person.name} className="rounded-2xl bg-gray-800 px-8 py-10">
                <img className="mx-auto h-48 w-48 rounded-full md:h-56 md:w-56" src={person.imageUrl} alt="" />
                <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-white">{person.name}</h3>
                <p className="text-sm leading-6 text-gray-400">{person.role}</p>
                <ul role="list" className="mt-6 flex justify-center gap-x-6">
                  <li>
                    <a href={person.mapUrl} target="_blank" className="text-gray-400 hover:text-gray-300">
                    <person.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
  