import React from 'react';
import './index.css';
import './styles.css';
import AppRoutes from './routes'; // Import the modified routes component
import reportWebVitals from './reportWebVitals';

const App = () => (
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>
);

export default App;
