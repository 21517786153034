import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

export default function Example() {
  return (
    <div style={{ backgroundImage: 'url("https://images.pexels.com/photos/1486785/pexels-photo-1486785.jpeg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div style={{ background: 'rgba(0, 0, 0, 0.3)' }} className="relative isolate pt-14">
      <div className="py-24 sm:pt-32 sm:pr-32 sm:pl-32 pb-0">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-3xl text-center">
              <h1 className="font-serif text-4xl tracking-tight text-gray-100 sm:text-6xl">
                Frank Montanaro<br></br>Real Estate
              </h1>
              <p className="font-serif italic mt-6 text-lg leading-8 text-gray-100">
              Unparalleled Service and Expert Advice at Every Step of the Real Estate Transaction

              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="tel:+15185222656"
                  className="bg-transparent hover:bg-blue-500 text-gray-100 hover:text-white py-2 px-4 border border-gray-100 hover:border-transparent rounded shadow"
                >
                  518.522.2656 <span aria-hidden="true"></span>
                </a>
               
              </div>
            </div>
            <div className="mt-16 flow-root sm:mt-24">
            <div className="mx-auto mt-10 grid max-w-sm items-center">
          <img
            src="https://cambridge-properties.s3.amazonaws.com/2023/montanaro1.png"
            alt="Frank"
          />
       
        </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
}
