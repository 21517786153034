// Home.tsx
import React from 'react';
import Hero from './Hero.tsx'; // Adjust the path based on your project structure
import PropertiesHome from './PropertiesHome.tsx';
import Testimonials from './Testimonials.tsx';

const Home: React.FC = () => {
  return (
    <div>
      <Hero />
      {/* Rest of your Home component content */}
    </div>
  );
}

export default Home;
